<template>
  <div>
    <img
      class="img-fluid"
      src="assets/img/video-defensoria.png"
      style="cursor: pointer"
      @click="openVideo"
    />
    <a
      href="assets/doc/DDC-Manual-ingreso-de-reclamos-Usuarios-DDC.pdf"
      target="_blank"
      >Descargar manual</a
    >

    <v-dialog v-model="dialog" max-width="950">
      <v-card max-height="550" height="550">
        <v-card-title
          >Videos
          <v-spacer></v-spacer>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="mx-auto">
          <video-player
            class="mx-auto"
            ref="videoPlayer"
            :options="playerOptions"
            :playsinline="true"
            customEventName="customstatechangedeventname"
          >
          </video-player>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="comoRegistrarse">Como registrarse</v-btn>
          <v-btn text @click="comoIngresarReclamo"
            >Como Ingresar un reclamo</v-btn
          >
          <v-btn text @click="comoHacerSeguimiento"
            >Como hacer seguimiento a un reclamo</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";

export default {
  components: {
    videoPlayer,
  },
  methods: {
    openVideo() {
      this.dialog = true;
    },
    comoRegistrarse() {
      this.playerOptions.sources[0].src = "/assets/Video/1. Registro.mp4";
    },
    comoIngresarReclamo() {
      this.playerOptions.sources[0].src =
        "/assets/Video/2. como-hacer-un-reclamo.mp4";
    },
    comoHacerSeguimiento() {
      this.playerOptions.sources[0].src = "/assets/Video/3. Seguimiento.mp4";
    },
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  data() {
    return {
      dialog: false,
      playerOptions: {
        // videojs options
        height: "400",
        width: "900",
        autoplay: true,
        muted: true,
        language: "es",
        sources: [
          {
            type: "video/mp4",
            src: "/assets/Video/1. Registro.mp4",
          },
          {
            type: "video/mp4",
            src: "/assets/Video/2. como-hacer-un-reclamo.mp4",
          },
          {
            type: "video/mp4",
            src: "/assets/Video/3. Seguimiento.mp4",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
