<template>
    <v-dialog max-width="700" persistent v-model="dialog">
        <template>
          <table class="header" align="center" border="0" cellpadding="0" cellspacing="0" width="700" style="height: 120px">
            <tbody>
                <tr>
                    <td class="text-center">
                        <v-btn class="mx-2" fab dark small color="#028e8e" @click="close">
                          <v-icon dark>mdi-close</v-icon>
                        </v-btn>
                    </td>
                    <td class="padding-left">
                        <h1 class="nombre" style="text-align: left;">Comunicado Cliente</h1>
                    </td>

                    <td class="padding-left">
                        <img src="assets/img/logo/defensoria-logo-nuevo-h.png" style="float: right; max-width: 160px;">
                    </td>
                </tr>
            </tbody>
          </table>
          <table class="contenido" align="center" border="0" cellpadding="0" cellspacing="0" width="700">
            <tbody>
                <tr>
                    <td class="texto padding-left">
                        <h1 class="titulo">Estimadas y Estimados Clientes:</h1>
                        <p style="text-align: justify;">
                            Nos encontramos actualizando nuestro sitio web para otorgarles una mejor experiencia, por lo que pedimos las disculpas del caso.<br/><br/>
                            Si eres nuevo, accede a la sección “REGISTRO”, completa tus datos, y luego ingresa tu consulta o reclamo.
                            Si ya tenías una cuenta, accede a la sección “INGRESAR” y luego presiona “Recuperar Contraseña”.<br/><br/>
                            Para mayor información, puedes acceder a nuestra sección de <a class="font-weight-black" @click="goToQuestions">Preguntas Frecuentes</a>.
                            Si tienes problemas para ingresar o ver tu consulta o reclamo, no dudes en contactar a:
                        </p>
                        <p style="text-align: justify;">
                          <ul>
                            <li>contacto@defensoriadelcliente.cl</li>
                            <li>Teléfono: 228922891</li>
                          </ul>
                        </p>
                        <p style="text-align: justify;">Atentamente,</p>
                    </td>
                </tr>
                <tr>
                    <td class="texto padding-left">
                        <h1 class="titulo" style="float: right;">Defensoría del Cliente.</h1>
                    </td>
                </tr>
            </tbody>
          </table>
        </template>
      </v-dialog>
</template>

<script>
    export default {
        methods: {
          close() {
            this.$store.dispatch("notifications/dialogConfirm");
          },
          goToQuestions() {
            const resolved = this.$router.resolve({name: "questions"});
            if (this.$router.currentRoute.fullPath == resolved.href)
                return;
                
            this.$router.push({name: "questions"});

            this.$store.dispatch("notifications/dialogConfirm");
          }
        },
        computed: {
          dialog() {
            return false;//this.$store.getters["notifications/dialogRelease"];
          }
        },
    }
</script>

<style lang="scss" scoped>
body {
    color: #333;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    background: transparent;
}

a {
    text-decoration: none;
    color: #000;
}

h1 {
    color: #fff;
    line-height: 0.6;
    font-size: 14px;
    font-weight: 500;
}

.nombre {
    color: #ffffff;
    line-height: 0.6;
    font-size: 18px;
    font-weight: bold;
}

.tabla-texto {
    background: #ffffff;
}

.bakground-block {
    background: #d04e50;
}

.texto {
    color: #666666;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-align: left !important;
}

.contrast {
    color: #ffffff !important;
}

.legales {
    color: #505b6a;
    font-family: verdana;
    font-size: 10px !important;
    text-align: center;
    text-decoration: none;
    padding: 5px 10px 10px 10px !important;
    background: #fff;
}

.header {
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px !important;
    text-align: left;
    background: #31a0a0;
    border-radius: 30px 30px 0 0;
}

.contenido {
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px !important;
    text-align: left;
    background: #e6e6e6;
    border-radius: 0 0 30px 30px;
}

.footer {
    text-align: left !important;
    text-decoration: none;
}

.titulo {
    color: #028e8e;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 40px;
    text-align: left !important;
}

.ReadMsgBody {
    width: 100%;
}

.ExternalClass {
    width: 100%;
}

table {
    border-collapse: collapse;
}

.align-center {
    text-align: center !important;
}

.padding-all {
    padding: 20px;
}

.padding-0 {
    padding: 0;
}

.padding-1 {
    padding: 0 20px 20px 20px;
}

.padding-2 {
    padding: 0 0 20px 20px;
}

.padding-3 {
    padding: 0 0 20px 0;
}

.padding-4 {
    padding: 10px;
}

.padding-5 {
    padding: 8px;
}

.padding-left {
    padding: 30px 40px 10px 50px;
}

.display-block {
    display: block;
}

@media screen and (max-width: 525px) {
    .image {
        width: 100%;
        height: auto;
    }
    .tabla-texto {
        width: 100% !important;
        text-align: center;
    }
    .padding {
        padding: 5px 10px 5px 10px !important;
    }
    .footer {
        padding: 5px 10px 10px 10px !important;
        text-align: center !important;
    }
}

.image {
    width: 100%;
    height: auto;
}

img {
    display: block;
}
</style>