<template>
  <main>
    <header
      id="js-header"
      class="u-shadow-v18 g-py-0 u-header  u-header--toggle-section u-header--change-appearance"
      data-header-fix-moment="300"
    >
      <div
        class="u-header__section u-header__section--light g-bg-white g-transition-0_3 g-py-10"
        data-header-fix-moment-exclude="g-py-10"
        data-header-fix-moment-classes="u-shadow-v18 g-py-0"
      >
        <nav
          class="js-mega-menu navbar navbar-expand-lg hs-menu-initialized hs-menu-horizontal fixed-top bg-white g-py-10"
        >
          <!-- Responsive Toggle Button -->
          <button
            class="navbar-toggler navbar-toggler-right btn g-line-height-1 g-brd-none g-pa-0 g-pos-abs g-top-minus-3 g-right-0"
            type="button"
            aria-label="Toggle navigation"
            aria-expanded="false"
            aria-controls="navBar"
            data-toggle="collapse"
            data-target="#navBar"
          >
            <span class="hamburger hamburger--slider">
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
            </span>
          </button>
          <!-- End Responsive Toggle Button -->

          <!-- Logo -->
          <router-link
            :to="{ name: 'resume' }"
            class="navbar-brand g-pl-50"
            v-if="currentUserIsAuthenticated"
          >
            <img
              src="assets/img/logo/defensoria-logo-nuevo-h.png"
              alt="logo defensoria"
              style="max-height: 50px;"
            />
          </router-link>
          <router-link
            :to="{ name: 'begin' }"
            class="navbar-brand g-pl-50"
            v-else
          >
            <img
              src="assets/img/logo/defensoria-logo-nuevo-h.png"
              alt="logo defensoria"
              style="max-height: 50px;"
            />
          </router-link>
          <!-- End Logo -->

          <!-- Navigation -->
          <div
            class="collapse navbar-collapse align-items-center flex-sm-row g-pt-10 g-pt-5--lg"
            id="navBar"
          >
            <ul
              class="navbar-nav text-uppercase g-pos-rel g-font-weight-600 ml-auto"
            >
              <li class="nav-item g-mx-10--lg g-mx-15--xl">
                <router-link
                  :to="{ name: 'defenders' }"
                  class="nav-link g-py-7 g-px-0"
                  >DEFENSORES</router-link
                >
              </li>
              <li class="nav-item g-mx-10--lg g-mx-15--xl">
                <router-link
                  :to="{ name: 'associates' }"
                  class="nav-link g-py-7 g-px-0"
                  >ASOCIADOS</router-link
                >
              </li>
              <li class="nav-item g-mx-10--lg g-mx-15--xl">
                <router-link
                  :to="{ name: 'statutes' }"
                  class="nav-link g-py-7 g-px-0"
                  >ESTATUTOS Y PROTOCOLOS</router-link
                >
              </li>
              <li class="nav-item g-mx-10--lg g-mx-15--xl">
                <router-link
                  :to="{ name: 'questions' }"
                  class="nav-link g-py-7 g-px-0"
                  >PREGUNTAS FRECUENTES</router-link
                >
              </li>
              <li class="nav-item g-mx-10--lg g-mx-15--xl">
                <router-link
                  :to="{ name: 'contact' }"
                  class="nav-link g-py-7 g-px-0"
                  >CONTACTO</router-link
                >
              </li>
              <li
                class="nav-item g-mx-10--lg g-mx-15--xl"
                v-if="currentUserIsAuthenticated"
              >
                <router-link
                  :to="{ name: 'resume' }"
                  class="nav-link g-py-7 g-px-0 menu-account-name"
                  ><v-icon>mdi-account</v-icon>
                  {{ currentUserInfo.name }}</router-link
                >
              </li>
              <li
                class="nav-item g-mx-10--lg g-mx-15--xl"
                v-if="currentUserIsAuthenticated"
              >
                <router-link
                  :to="{ name: 'logoutfront' }"
                  class="nav-link g-py-7 g-px-0"
                  ><i class="fa fa-times-rectangle"></i> CERRAR
                  SESIÓN</router-link
                >
              </li>
              <div class="d-lg-flex g-mx-15--xl g-pt-7" v-else>
                <ul class="u-list-inline">
                  <li class="list-inline-item">
                    <router-link
                      to="#"
                      v-on:click.native="showDialogLogin = true"
                      class="g-text-underline--none--hover g-color-gray-dark-v3"
                      >INGRESAR</router-link
                    >
                  </li>
                  <li class="list-inline-item">
                    <span>|</span>
                  </li>
                  <li class="list-inline-item">
                    <router-link
                      :to="{ name: 'registrationSelectionUserType' }"
                      class="g-text-underline--none--hover g-color-gray-dark-v3"
                      >REGISTRO</router-link
                    >
                  </li>
                </ul>
              </div>
            </ul>
          </div>
          <!-- End Navigation -->
        </nav>
      </div>
      <Login
        :showDialogLogin="showDialogLogin"
        @abort="showDialogLogin = false"
      />
    </header>

    <div v-if="$route.name == 'begin'">
      <section
        class="g-bg-pos-center g-bg-size-cover"
        style="background-image: url(assets/img/fondo-defensoria-wide.png);"
      >
        <div class="row no-gutters">
          <div class="col-md-6 order-md-1 g-bg-blue">
            <div class="g-px-50 g-py-50">
              <div class=" g-mb-100">
                <h1
                  class="g-color-white g-font-weight-600 g-font-size-40 g-letter-spacing-0_5 mb-4 font-color-title"
                >
                  ¿Qué hacemos?
                </h1>
                <p
                  class="lead g-color-white g-font-weight-400 font-color-subtitle"
                >
                  La Asociación de Bancos e Instituciones Financieras (ABIF) ha
                  establecido un sistema de resolución de conflictos mediante el
                  cual los clientes de los bancos adscritos cuentan con una
                  instancia independiente donde sus reclamos pueden ser
                  resueltos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="container-fluid g-px-20 g-pt-60 g-pb-30">
        <div class="row no-gutters g-mx-minus-10">
          <div class="col-sm-6 col-lg-4 g-px-10 g-pl-20 ">
            <router-link
              :to="{ name: 'selectionUserIsRegistered' }"
              class="btn btn-block btn-xl u-btn-cyan u-btn-content g-mr-2  g-ml-2   g-mb-15 "
              ><img src="assets/img/reclamo.svg" />Ingresa un
              reclamo</router-link
            >
          </div>

          <div class="col-sm-6 col-lg-4 g-px-10">
            <router-link
              :to="{ name: 'selectionUserIsRegisteredQuery' }"
              class="btn btn-block btn-xl u-btn-orange u-btn-content g-mr-2 g-mb-15  "
              ><img src="assets/img/consulta.svg" /> Ingresa una
              consulta</router-link
            >
          </div>

          <div class="col-sm-6 col-lg-4 g-px-10">
            <router-link
              to="#"
              v-on:click.native="showDialogLogin = true"
              class="btn btn-block btn-xl u-btn-bluegray u-btn-content g-mr-2 g-mb-15   flex-nowrap"
              ><img src="assets/img/seguimiento.svg" /> Realizar seguimiento del
              caso</router-link
            >
          </div>
        </div>
      </section>

      <section class="container g-py-20 text-center">
        <div class=" row no-gutters g-mx-minus-10">
          <div class="col-sm-12 col-lg-12 g-px-10 g-mb-20">
            <a href="#pasos" class="scroll-down" address="true"
              ><img src="assets/img/chevron-down.svg" />
            </a>
          </div>
        </div>
      </section>
      <section id="pasos" class="container g-px-0">
        <div class="row align-items-center no-gutters">
          <div
            class="col-md-6 col-sm-6 xs-display-block d-lg-none md-display-none sm-display-none"
          >
            <div class="text-center g-pa-15x">
              <div class="mb-5">
                <h3 class="h2 g-color-bluegray mb-4 g-font-weight-600 body">
                  ¿Cómo hacer un reclamo o ingresar una consulta?
                </h3>
                <img class="img-fluid" src="assets/img/video-defensoria.png" />
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-6 xs-display-none">
            <div
              class="text-center g-pa-15x"
              style="border-right: 1px solid #666666;"
            >
              <div class="mb-5">
                <h3 class="h2 g-color-bluegray mb-4  ">
                  ¿Cómo hacer un reclamo o ingresar una consulta?
                </h3>
                <Video />
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="g-flex-centered text-center">
              <div class="g-pa-25">
                <span
                  class="disabled-link btn btn-lg u-btn-bluegray g-mr-2 g-mb-15"
                  style="max-width: 400px;font-size: 25px"
                >
                  1.Complete el formulario disponible en el sitio.
                </span>
              </div>
            </div>

            <div class="g-flex-centered text-center">
              <div class="g-pa-25">
                <span
                  class="disabled-link btn btn-lg u-btn-bluegray g-mr-2 g-mb-15"
                  style="max-width: 400px;font-size: 25px"
                >
                  2.Un abogado defensor tomará tu reclamo o consulta.
                </span>
              </div>
            </div>

            <div class="g-flex-centered text-center">
              <div class="g-pa-25">
                <span
                  class="disabled-link btn btn-lg u-btn-bluegray g-mr-2 g-mb-15 "
                  style="max-width: 400px;font-size: 25px"
                >
                  3.Podrás realizar seguimiento y recibirás una respuesta a tu
                  reclamo.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Banks />
    </div>

    <router-view :key="$route.path"></router-view>

    <Footer />
    <DialogRelease />
  </main>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import currentUserMixin from "@/mixins/currentUser";

import Login from "@/components/PublicSite/Login";
import Footer from "@/components/PublicSite/Footer";
import Banks from "@/components/PublicSite/Banks";

import DialogRelease from "@/components/PublicSite/DialogRelease";

import Video from "@/components/PublicSite/Video";

export default {
  mixins: [baseViewMixin, currentUserMixin],
  components: {
    Login,
    Banks,
    Footer,
    DialogRelease,
    Video,
  },
  data() {
    return {
      showDialogLogin: false,
    };
  },
  methods: {
    showConsult() {
      this.showDialogConsult = true;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  beforeMount() {
    window.scrollTo(0, 0);
  },
  updated() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
a.btn {
  color: white !important;
}
.btn-size {
  font-size: 25px !important;
}
.font-color-subtitle {
  font-weight: 400;
  font-size: 1rem;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #fff !important;
}
.font-color-title {
  font-weight: 600 !important;
  font-size: 2.85714rem !important;
  font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
  color: #fff !important;
}
body {
  font-weight: 400;
  font-size: 17px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  line-height: 1.6;
  color: #555;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  background-color: #fff;
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.disabled-link {
  cursor: default;
  pointer-events: none;
  text-decoration: none;
}
</style>
