<template>
    <section class="g-bg-white g-pa-60">
        <h3 class="g-color-gray-dark-v3 g-font-weight-400">Bancos Adscritos</h3>
        <div class="g-overflow-hidden">
            <div class="row text-center mx-0 g-ml-minus-1 g-mb-minus-1">
                <div class="col-md-3 px-0">
                    <div class="u-block-hover g-brd-left g-brd-bottom g-brd-gray-light-v4 g-py-40">
                        <img class="u-block-hover__main--grayscale g-width-120 g-opacity-0_3 g-opacity-1--hover g-cursor-pointer" src="assets/img/bancos/Banco_de_Chile_Logo.png" @click="showBankPage('BCH')" alt="Image Description">
                    </div>
                </div>

                <div class="col-md-3 px-0">
                    <div class="u-block-hover g-brd-left g-brd-bottom g-brd-gray-light-v4 g-py-40">
                        <img class="u-block-hover__main--grayscale g-width-120 g-opacity-0_3 g-opacity-1--hover g-cursor-pointer" src="assets/img/bancos/bci.png" @click="showBankPage('BBC')" alt="Image Description">
                    </div>
                </div>

                <div class="col-md-3 px-0">
                    <div class="u-block-hover g-brd-left g-brd-bottom g-brd-gray-light-v4 g-py-40">
                        <img class="u-block-hover__main--grayscale g-width-120 g-opacity-0_3 g-opacity-1--hover g-cursor-pointer" src="assets/img/bancos/santander-logo-color_0.png" @click="showBankPage('BSA')" alt="Image Description">
                    </div>
                </div>

                <div class="col-md-3 px-0">
                    <div class="u-block-hover g-brd-left g-brd-bottom g-brd-gray-light-v4 g-py-40">
                        <img class="u-block-hover__main--grayscale g-width-120 g-opacity-0_3 g-opacity-1--hover g-cursor-pointer" src="assets/img/bancos/bancoestado.png" @click="showBankPage('BES')" alt="Image Description">
                    </div>
                </div>

                <div class="col-md-3 px-0">
                    <div class="u-block-hover g-brd-left g-brd-bottom g-brd-gray-light-v4 g-py-40">
                        <img class="u-block-hover__main--grayscale g-width-120 g-opacity-0_3 g-opacity-1--hover g-cursor-pointer" src="assets/img/bancos/scotibank.png" @click="showBankPage('BSC')" alt="Image Description">
                    </div>
                </div>

                <div class="col-md-3 px-0">
                    <div class="u-block-hover g-brd-left g-brd-bottom g-brd-gray-light-v4 g-py-40">
                        <img class="u-block-hover__main--grayscale g-width-120 g-opacity-0_3 g-opacity-1--hover g-cursor-pointer" src="assets/img/bancos/itau.png" @click="showBankPage('BIT')" alt="Image Description">
                    </div>
                </div>

                <div class="col-md-3 px-0">
                    <div class="u-block-hover g-brd-left g-brd-bottom g-brd-gray-light-v4 g-py-40">
                        <img class="u-block-hover__main--grayscale g-width-120 g-opacity-0_3 g-opacity-1--hover g-cursor-pointer" src="assets/img/bancos/Banco-Security.png" @click="showBankPage('BSE')" alt="Image Description">
                    </div>
                </div>

                <div class="col-md-3 px-0">
                              <div class="u-block-hover g-brd-left g-brd-bottom g-brd-gray-light-v4 g-py-40">
                        <img class="u-block-hover__main--grayscale g-width-120 g-opacity-0_3 g-opacity-1--hover g-cursor-pointer" src="assets/img/bancos/Logo_Ripley_banco_2.png" @click="showBankPage('BRI')" alt="Image Description">
                    </div>
                </div>
                 <!-- <div class="col-md-3 px-0">
                                 <div class="u-block-hover g-brd-left g-brd-bottom g-brd-gray-light-v4 g-py-40">
                     <img class="u-block-hover__main--grayscale g-width-120 g-opacity-0_3 g-opacity-1--hover g-cursor-pointer"  src="assets/img/bancos/falabella.png" @click="showBankPage('BFAL')" alt="Image Description">
                    </div>
                </div> -->
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        methods: {
          showBankPage(bank) {
            switch (bank) {
                case "BBC":
                    window.open("https://ww2.bci.cl/personas", "_blank ");
                    break;
                case "BCH":
                    window.open("https://portales.bancochile.cl/personas", "_blank ");
                    break;
                case "BRI":
                    window.open("https://www.bancoripley.cl", "_blank ");
                    break;
                case "BSA":
                    window.open("https://banco.santander.cl", "_blank ");
                    break;
                case "BES":
                    window.open("https://www.bancoestado.cl", "_blank ");
                    break;
                case "BSC":
                    window.open("https://www.scotiabankchile.cl", "_blank ");
                    break;
                case "BIT":
                    window.open("https://www.itau.cl", "_blank ");
                    break;
                case "BSE":
                    window.open("https://personas.bancosecurity.cl", "_blank ");
                    break;
                      case "BFAL":
                    window.open("https://www.bancofalabella.cl", "_blank ");
                    break;
                default:
                    break;
            }
          },
        },
    }
</script>
